import { memo } from 'react'

import { Card } from 'components/Card'

import { Flex } from '@inspectornexus/components'

import { FontDetails } from './FontDetails'

export const TypographyCard = memo(() => (
  <Card heading="Typography">
    <Flex px={3} width="100%" pt={3} pb={4} flexDirection="column">
      <Flex height="100%" alignItems="stretch">
        <FontDetails fontTitle="SF Pro Display" fontFamily="SF Pro Display" />
        <FontDetails fontTitle="SF Pro Text" fontFamily="SF Pro Text" />
      </Flex>
    </Flex>
  </Card>
))

TypographyCard.displayName = 'TypographyCard'
