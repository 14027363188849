import type { ReactNode } from 'react'
import React, { memo } from 'react'

import type { IFlexProps } from '@inspectornexus/components'
import { Flex, Heading, Link } from '@inspectornexus/components'
import { Download } from '@inspectornexus/icons'

interface IProps extends IFlexProps {
  downloadUrl: string
  logo: ReactNode
  logoTitle: string
}

export const LogoDetails = memo<IProps>(
  ({ logoTitle, logo, downloadUrl, ...rest }) => (
    <Flex
      height="100%"
      ml={3}
      flexDirection="column"
      alignItems="space-between"
      width="50%"
      {...rest}
    >
      <Flex alignItems="center" mb={2}>
        <Flex
          mr={1}
          alignItems="center"
          style={{ transform: 'translateY(-1px)' }}
        >
          <Link href={downloadUrl}>
            <Download color="inherit" size={14} />
          </Link>
        </Flex>
        <Heading fontWeight={700} fontSize={1} as="h3">
          {logoTitle}
        </Heading>
      </Flex>
      <Flex
        justifyContent="flex-end"
        alignItems="flex-start"
        height="100%"
        flexDirection="column"
      >
        {logo}
      </Flex>
    </Flex>
  )
)

LogoDetails.displayName = 'LogoDetails'
