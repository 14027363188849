import React, { memo } from 'react'

import type { IFlexProps } from '@inspectornexus/components'
import { Flex, Heading, Text } from '@inspectornexus/components'
import type { ITheme } from '@inspectornexus/theme'
import { getColor } from '@inspectornexus/theme'

interface IProps extends IFlexProps {
  color: keyof ITheme['colors']
  colorTitle: string
}

export const ColorDetails = memo<IProps>(({ colorTitle, color, ...rest }) => (
  <Flex
    height="100%"
    ml={3}
    flexDirection="column"
    mb={1}
    alignItems="space-between"
    width="50%"
    {...rest}
  >
    <Heading fontWeight={700} fontSize={1} as="h3" mb={2}>
      {colorTitle}
    </Heading>
    <Flex flexDirection="column" alignItems="flex-start" height="100%">
      <Flex height={64} width={64} bg={color} />
      <Text mt={1} fontSize={0} color="text3">
        {getColor(color)}
      </Text>
    </Flex>
  </Flex>
))

ColorDetails.displayName = 'ColorDetails'
