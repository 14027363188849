import type { ReactNode } from 'react'
import { memo } from 'react'

import { Flex, Heading } from '@inspectornexus/components'

interface IProps {
  children: ReactNode
  heading: string
}

export const Card = memo<IProps>(({ heading, children }) => (
    <Flex
      mb={3}
      bg="container"
      width="100%"
      justifyContent="space-between"
      pt={4}
      pb={1}
      flexDirection="column"
    >
      <Heading
        mx={4}
        fontWeight={700}
        fontSize={0}
        letterSpacing={1}
        textTransform="uppercase"
        as="h6"
        color="accent2"
        mb={1}
      >
        {heading}
      </Heading>
      {children}
    </Flex>
  ))

Card.displayName = 'Card'
