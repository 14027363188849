import React, { memo } from 'react'

import type { IFlexProps } from '@inspectornexus/components'
import { Flex, Heading, Text } from '@inspectornexus/components'

interface IProps extends IFlexProps {
  fontFamily: string
  fontTitle: string
}

export const FontDetails = memo<IProps>(
  ({ fontTitle, fontFamily, ...rest }) => (
    <Flex
      height="100%"
      ml={3}
      flexDirection="column"
      mb={1}
      alignItems="space-between"
      width="50%"
      {...rest}
    >
      <Heading fontWeight={700} fontSize={1} as="h3" mb={2}>
        {fontTitle}
      </Heading>
      <Flex
        alignItems="flex-start"
        height="100%"
        flexDirection="column"
        overflow="hidden"
      >
        <Text
          fontSize={5}
          style={{
            overflowWrap: 'anywhere'
          }}
          fontFamily={fontFamily}
        >
          ABCDEFGHIJKLMNOPQRSTUVWXYZ
        </Text>
        <Text
          fontSize={3}
          fontFamily={fontFamily}
          style={{
            overflowWrap: 'anywhere'
          }}
        >
          abcdefghijklmnopqrstuvwxyz
        </Text>
      </Flex>
    </Flex>
  )
)

FontDetails.displayName = 'FontDetails'
