import { memo } from 'react'

import styled from '@emotion/styled'
import { Flex } from '@inspectornexus/components'

import { ColorsCard } from './ColorsCard'
import { HeadingCard } from './HeadingCard'
import { LogosCard } from './LogosCard'
import { TypographyCard } from './TypographyCard'

const RootContainer = styled.div`
  position: absolute;
  display: block;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-y: overlay;
  -webkit-overflow-scrolling: touch;
`

export const App = memo(() => (
    <RootContainer>
      <Flex
        minWidth="650px"
        width="100%"
        bg="wrapper"
        justifyContent="center"
        p={4}
        height="100%"
      >
        <Flex minWidth="600px" width="600px" flexDirection="column">
          <HeadingCard />
          <LogosCard />
          <ColorsCard />
          <TypographyCard />
          <Flex minHeight="1px" maxHeight="1px" />
        </Flex>
      </Flex>
    </RootContainer>
  ))

App.displayName = 'App'
