import { memo } from 'react'

import { Card } from 'components/Card'

import { Flex } from '@inspectornexus/components'

import { ColorDetails } from './ColorDetails'

export const ColorsCard = memo(() => (
  <Card heading="Colors">
    <Flex px={3} width="100%" pt={3} pb={4} flexDirection="column">
      <Flex mb={4}>
        <ColorDetails colorTitle="Pacific" color="brand2" />
        <ColorDetails colorTitle="Midnight" color="darkContainer" />
      </Flex>
      <Flex>
        <ColorDetails colorTitle="Azure" color="accent2" />
        <ColorDetails colorTitle="Ghost" color="wrapper" />
      </Flex>
    </Flex>
  </Card>
))

ColorsCard.displayName = 'ColorsCard'
