import React, { memo } from 'react'

import { Flex, Text } from '@inspectornexus/components'
import { AppIcon } from '@inspectornexus/icons'

export const HeadingCard = memo(() => (
  <Flex bg="container" width="100%" borderTop="8px solid black" mb={3}>
    <Flex
      width="100%"
      px={6}
      pb={3}
      pt={5}
      mb={3}
      alignItems="center"
      justifyContent="flex-start"
    >
      <AppIcon size={39} />
      <Flex
        ml={2}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Text fontSize={5} fontWeight={600} lineHeight={1.1}>
          INSPECTOR NEXUS
        </Text>
        <Text fontSize={3} color="text2" lineHeight={1.1}>
          STYLE GUIDE
        </Text>
      </Flex>
    </Flex>
  </Flex>
))

HeadingCard.displayName = 'HeadingCard'
