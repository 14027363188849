import { memo } from 'react'

import { Card } from 'components/Card'

import { Flex } from '@inspectornexus/components'
import {
  AppIcon,
  InnerLogo,
  MonotoneLogo,
  StandardLogo
} from '@inspectornexus/icons'

import { LogoDetails } from './LogoDetails'

export const LogosCard = memo(() => (
  <Card heading="Logos">
    <Flex px={3} width="100%" pt={3} pb={4} flexDirection="column">
      <Flex mb={4} justifyContent="space-between">
        <LogoDetails
          logoTitle="Standard"
          logo={
            <StandardLogo
              size={54}
              color="black"
              style={{ transform: 'translateY(6px)' }}
            />
          }
          downloadUrl="logos/standard.svg"
        />
        <LogoDetails
          logoTitle="Dark"
          logo={<MonotoneLogo fill="light" color="darkContainer" size={64} />}
          downloadUrl="logos/inverted.svg"
        />
      </Flex>
      <Flex mb={4}>
        <LogoDetails
          mb={2}
          logoTitle="Standard Icon"
          logo={<AppIcon size={64} />}
          downloadUrl="logos/standardIcon.svg"
        />
        <LogoDetails
          logoTitle="Dark Icon"
          logo={<AppIcon size={64} color="darkContainer" />}
          downloadUrl="logos/darkIcon.svg"
        />
      </Flex>
      <Flex>
        <LogoDetails
          mb={2}
          logoTitle="Tag Icon"
          logo={<InnerLogo color="brand2" size={34} />}
          downloadUrl="logos/tagIcon.svg"
        />
        <LogoDetails
          logoTitle="Dark Tag Icon"
          logo={<InnerLogo size={34} color="darkContainer" />}
          downloadUrl="logos/darkTagIcon.svg"
        />
      </Flex>
    </Flex>
  </Card>
))

LogosCard.displayName = 'LogosCard'
